import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Suspense, lazy } from "react";
import { AuthProvider } from "hooks/useAuth";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CustomLoader } from "components/common";

const RoutesComponent = lazy(() => import("routes"));

function App() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Suspense fallback={<CustomLoader />}>
            <RoutesComponent />
          </Suspense>
        </AuthProvider>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
